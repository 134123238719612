import axiosService from '~/services/axios.service.js'

export function trackEvent(event, data) {
  return axiosService.post('/track-users', { action: event, data: data })
}

export function trackSession(uri) {
  return axiosService.post('/track-sessions', { uri: uri })
}

export const slugToTrackingName = (slug) => {
  return slug.replace(/-/g, '_').toUpperCase() === 'INDEX' ? 'STATS_SUMMARY' : slug.replace(/-/g, '_').toUpperCase()
}
